@import url('./index.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root h1 {
  color: #00C2EF;
  font-size: var(--font-size-h1);
  line-height: 39px;
  font-family: "PoppinsBold", serif;
  margin:0;
}

.Document-card-text-bold {
  text-align: left;
  font-family: "PoppinsSemiBold", serif;
}

.IconsNavigationContainer {
  margin-bottom: 54px;
}
.CristalIconButton {
  height: 24px;
}

/* || Components styles */

/* - Labels - */

.light-theme .label {
  color: var(--color-text-light);
}

.dark-theme.label {
  color: var(--color-text-dark);
}

.IconLabelLight {
  color: var(--color-text-light);
}
.IconLabelDark {
  color: var(--color-text-dark);
}


.scrollbar-thumb {
  background-color: #00C2EF!important;
}

.CristalGridContainerCategoryItem{
  position: absolute;
  bottom: 0;
  left: 0;
}

.CristalGridItemCategory {
  margin-left: 15px!important;
  margin-bottom: 8px!important;
}

.htmlContent p {
  margin-bottom: 12px;
}
.htmlContent li {
  list-style: inside;
}


