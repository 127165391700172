/*
Styles globaux
reinitialisation navigateur
font globales
medias queries
variable colorimétrique
 */

* {
  margin: 0;
  padding: 0;
  font-family: PoppinsRegular, PoppinsSemiBold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;;
}
*, html {
  scroll-behavior: smooth;
}

:root {
  --color-primary-blue: #00C2EF;
  --color-primary-blue-deep: #00243F;
  --color-primary-orange: #FFC107;
  --color-primary-pink: #E11279;

  /* || Backgrounds */
  --background-global-01-light: #FFFFFF;
  --background-global-01-dark: #00243F;

  /* || Backgrounds card */
  --background-global-02-light: #CCF3FC;
  --background-global-02-dark: #004072;

  /* || Backgrounds card */
  --background-global-03-light: #B3EDFA;
  --background-global-03-dark: #00508F;

  --border-card-light: #CCF3FC;
  --border-card-dark: #003B5D;

  --hover-icon-cristal-dark: #004072;

  --icon-color-border-light : #00C2EF;

  /* || Content (Texts, Icon, etc...) */

  --color-text-light: #00243F;
  --color-text-dark: #FFFFFF;

  --color-text-label-light: #00508F;
  --color-text-label-dark: #FFFFFF;

  /* || Fonts  */
  --font-family-app: PoppinsRegular,PoppinsSemiBold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';

  --font-size-body: 16px;
  --font-size-label: 14px;
  --font-size-h1: 36px;
  --font-size-h2: 23px;
  --font-size-h3: 20px;
}

/* TODO media queries */
body {
  background-image: url('./images/bg-bleu-body-top.svg');
  background-repeat: no-repeat;
  background-size: 600px 600px;
  background-position: -130px -150px;
  text-align: left;
}


.dark-theme {
  background-color: var(--background-global-01-dark);
  color: var( --color-text-dark);

}

.light-theme {
  background-color: var(--background-global-01-light);
  color: var( --color-text-light);
}



/* Font import */
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
  url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
  url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
  url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
  url("./fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

